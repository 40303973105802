<template>
  <div>
    <div class="line" />
    <div class="steps">
      <template v-for="(step, index) in processedSteps">
        <filing-tracker-step
          :key="step.title"
          :to="step.to"
          :icon="step.icon"
          :title="step.title"
          :quip="step.quip"
          :blurb="step.blurb"
          :description="step.description"
          :is-active="step.isActive"
          :is-blocking="step.isBlocking"
          :is-in-second-half="index >= processedSteps.length / 2"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilingTracker",
  components: {
    FilingTrackerStep: () => import('@/components/Filing/PendingFiling/FilingTrackerStep.vue'),
  },
  props: {
    status: String,
    filingName: String,
    estimate: String,
    steps: Array,
  },
  computed: {
    processedSteps() {
      return this.steps.reduce((accumulator, step) => {
        const { status, filingName } = this
        const { config, filings, statuses } = step

        const isActive = statuses.includes(status)
        const isRelevant = filings.includes(filingName)
        const shouldIncludeStep = config.isStatic ? isRelevant : isRelevant && isActive

        if (shouldIncludeStep) {
          // evaluate dynamic config values
          Object.entries(config).forEach(([key, value]) => {
            if (typeof value === 'function') {
              config[key] = value({ status, filingName })
            }
          })

          accumulator.push({ ...config, isActive })
        }

        return accumulator
      }, [])
    },
  },
}
</script>

<style lang="scss" scoped>
$line-color: #e6e6ed;

// desktop styles
@media (min-width: 736px) {
  .line {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.5rem;
    margin: 2rem 3rem 0 3rem;
    background: $line-color;
    border-radius: 1.25rem;
  }

  .steps {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 1.5rem 4rem 1rem 4rem;
  }
}

// mobile styles
@media (max-width: 735px) {
  .steps {
    margin: 3rem 0;
    padding-right: 1rem;
  }
}
</style>
